import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { BonusType } from '@longnecktech/splash-commons-fe/dist/types/bonus-type.enum';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { SessionService } from '@services/session.service';
import { TranslationPipe } from '@shared/pipes/translation.pipe';
import Hammer from 'hammerjs';

export interface CarouselItem {
  link: string;
  assetUrl: string;
  ctaLabel: string;
  name?: string;
  bonusType?: BonusType;
}

@UntilDestroy()
@Component({
  selector: 'app-game-carousel',
  standalone: true,
  imports: [CommonModule, TranslationPipe],
  templateUrl: './game-carousel.component.html',
  styleUrl: './game-carousel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameCarouselComponent implements AfterViewInit, OnDestroy {
  @ViewChild('carousel') carouselRef!: ElementRef;
  @ViewChild('carouselContainer') containerRef!: ElementRef;

  @Input() isMobilePage?: boolean;
  @Input() data!: CarouselItem[];
  @Input() hasWon = false;
  @Output() readonly navigate = new EventEmitter<string>();
  @Output() readonly dismiss = new EventEmitter<void>();

  currentIndex = 1;
  game$ = this.session.game$;
  private margin = 0;
  private blockWidth = 0;
  private hammer: HammerManager | null = null;

  get translation(): string {
    if (!this.hasWon) {
      return 'lost.playNow';
    }

    const item = this.data[this.currentIndex];
    if (item?.ctaLabel) {
      return item.ctaLabel;
    }

    if (
      !item?.bonusType ||
      item.bonusType === BonusType.FreeSpins ||
      item.bonusType === BonusType.NonDeposit
    ) {
      return 'winnings.playNow';
    }

    return 'winnings.deposit';
  }

  constructor(
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    protected session: SessionService,
  ) {}

  ngAfterViewInit() {
    this.session.isMobile$.pipe(untilDestroyed(this)).subscribe((isMobile) => {
      this.margin = isMobile ? 16 : 24;
      this.blockWidth = (isMobile ? 224 : 288) + this.margin;
      if (this.data.length <= 2) {
        this.currentIndex = 0;
        this.cdr.detectChanges();
      }
      this.scrollToCenter(this.data);
      if (isMobile) {
        this.initSwipeGesture();
      } else {
        this.destroySwipeGesture();
      }
    });
  }

  ngOnDestroy() {
    this.destroySwipeGesture();
  }

  onNavigateToGame(): void {
    const item = this.data && this.data[this.currentIndex];
    if (!item || !item.link) {
      // if there is no link to claim the prize or play the game
      // then let's navigate the user to home page
      this.dismiss.emit();
      return;
    }
    this.navigate.emit(item.link);
  }

  changeActiveItem(direction: number) {
    if (!this.data?.length) return;

    const lastIndex = this.data.length - 1;

    if (
      !direction ||
      (this.currentIndex === 0 && direction === -1) ||
      (this.currentIndex === lastIndex && direction === 1)
    )
      return;

    this.currentIndex += direction;
    this.scrollToCenter(this.data);
    this.cdr.detectChanges();
  }

  private initSwipeGesture() {
    if (this.containerRef && !this.hammer) {
      this.ngZone.runOutsideAngular(() => {
        this.hammer = new Hammer(this.containerRef.nativeElement);
        this.hammer!.on('swipeleft swiperight', (ev: HammerInput) => {
          this.ngZone.run(() => {
            if (ev.type === 'swipeleft') {
              this.changeActiveItem(1);
            } else if (ev.type === 'swiperight') {
              this.changeActiveItem(-1);
            }
          });
        });
      });
    }
  }

  private destroySwipeGesture() {
    if (this.hammer) {
      this.hammer.destroy();
      this.hammer = null;
    }
  }

  private scrollToCenter(data: CarouselItem[]) {
    if (!data?.length) return;
    if (data.length === 1 && this.carouselRef) {
      const carousel = this.carouselRef.nativeElement;
      carousel.style.justifyContent = 'center';
      carousel.style.transform = 'translateX(0)';
      return;
    }
    if (this.carouselRef && this.containerRef) {
      const container = this.containerRef.nativeElement;
      const carousel = this.carouselRef.nativeElement;
      const containerWidth = container.offsetWidth;
      const scrollLeft =
        this.currentIndex * this.blockWidth -
        (containerWidth - this.blockWidth) / 2 -
        this.margin / 2;
      carousel.style.transform =
        scrollLeft > 0
          ? `translateX(-${scrollLeft}px)`
          : `translateX(${Math.abs(scrollLeft)}px)`;
    }
  }
}
