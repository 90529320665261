<section
  class="container flex flex-column"
  [class.mobile]="isMobile$ | async"
  [class.bounce-in-down]="(awardAnimationPlayed$ | async) === false">
  <header class="flex flex-justify-space-between">
    <button
      type="button"
      (click)="dismiss()"
      class="back-btn icon-btn"
      [class.mobile]="isMobile$ | async">
      <img alt="Close" src="assets/icons/back.svg" />
    </button>
    @if (isFromHub || (showCloseButton$ | async)) {
      <button
        type="button"
        (click)="close()"
        class="close-btn icon-btn"
        [class.mobile]="isMobile$ | async">
        <img alt="Close" src="assets/icons/close.svg" />
      </button>
    }
  </header>
  <div class="content">
    @if (won$ | async) {
      <div class="title-container">
        <span class="title text-large">{{ 'winnings.title' | translate }}</span>
      </div>
      <div class="winning-container">
        <app-game-carousel
          (dismiss)="dismiss()"
          (navigate)="playNow($event, true)"
          [hasWon]="true"
          [data]="(winningsData$ | async)!"
          [isMobilePage]="(isMobile$ | async)!">
        </app-game-carousel>
      </div>
    } @else {
      <div class="title-container">
        @if ((collectibles$ | async)?.length; as countCollectibles) {
          <span class="title text-large">
            @if (countCollectibles > 1) {
              {{ 'winnings.yourEarnCollectibles' | translate }}
            } @else {
              {{ 'winnings.yourEarnCollectible' | translate }}
            }
          </span>
          <span class="subtitle text-body1 no-win-subtitle">
            {{ 'winnings.noInstantWinYouHaveScore' | translate }}
            <span [innerHTML]="collectiblesTitle$ | async"></span>
            <strong>
              {{ 'winnings.tryAnotherGame' | translate }}
            </strong>
          </span>
        } @else {
          <span class="title text-large">
            {{ 'winnings.noPrizes' | translate }}
          </span>
          <span class="subtitle text-body1">
            {{ 'winnings.noWinTryGame' | translate }}
          </span>
        }

        <app-game-carousel
          (dismiss)="dismiss()"
          (navigate)="playNow($event, false)"
          [hasWon]="false"
          [data]="(featureProductsFroCarousel$ | async)!"
          [isMobilePage]="(isMobile$ | async)!">
        </app-game-carousel>
      </div>
    }
  </div>
</section>
