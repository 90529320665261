import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import {
  IframeMessage,
  Spinmachine,
  UserAction,
} from '@longnecktech/splash-commons-fe';
import { CarouselItem } from '@shared/components/game-carousel/game-carousel.component';
import { Game } from '@shared/types/game';
import { Winning } from '@shared/types/spin';
import { CollectibleGoalType } from 'src/app/shared/types/collectible-goal';
import { SessionService } from '@services/session.service';
import { emitIframeMessage } from '../../utils/common.utils';
import { take, map, filter, switchMap } from 'rxjs/operators';
import { CollectiblesService } from '@services/collectibles.service';
import { SpinService } from '@services/spin.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { delay, combineLatest } from 'rxjs';
import { ConfettiService } from '@services/confetti.service';

@UntilDestroy()
@Component({
  selector: 'app-winnings',
  templateUrl: './winnings.component.html',
  styleUrls: ['./winnings.component.scss'],
})
export class WinningsComponent implements OnInit {
  readonly types = CollectibleGoalType;
  collectibles$ = this.collectibleService.collectibles$.pipe(
    map((collectibles) =>
      collectibles.filter(
        (col) =>
          col.inThisSpinCollectedCount &&
          col.goal.type === CollectibleGoalType.COLLECTIBLE,
      ),
    ),
  );
  collectiblesTitle$ = this.collectibles$.pipe(
    map((collectibles) =>
      collectibles.reduce((acc, col) => {
        const label = `${col.inThisSpinCollectedCount}x <img width="14px" src="${col.goal.icon.url}"/>`;
        return acc ? `${acc}, ${label}` : label;
      }, ''),
    ),
  );
  featureProductsFroCarousel$ = this.session.game$.pipe(
    map((game) =>
      (game?.featuredProducts || []).map(
        (pr) =>
          ({
            link: pr.ctaLink,
            assetUrl: pr.asset.url,
          }) as CarouselItem,
      ),
    ),
  );
  spin$ = this.spinService.spin$.pipe(filter((spin) => !!spin));
  winnings$ = this.spin$.pipe(map((spins) => spins!.winnings));
  isMobile$ = this.session.isMobile$;
  game$ = this.session.game$;
  showCloseButton$ = this.game$.pipe(map((game) => !game!.hideCloseButton));
  isFromHub = this.session.isFromHub;
  awardAnimationPlayed$ = this.session.awardAnimationPlayed$;
  showNextSpinInfo$ = this.spinService.showNextSpinInfo$;
  winningsData$ = combineLatest([this.winnings$, this.game$]).pipe(
    map(([winnings, game]) => this.getWinningsData(winnings, game!)),
  );
  won$ = this.winnings$.pipe(map((winnings) => !!winnings!.length));

  constructor(
    private session: SessionService,
    private collectibleService: CollectiblesService,
    private spinService: SpinService,
    private confettiService: ConfettiService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.initConfetti();
    this.initSpinInfo();
    this.awardAnimationPlayed$
      .pipe(
        take(1),
        filter((played) => !played),
        delay(1500),
      )
      .subscribe(() => {
        this.session.setAwardAnimation(true);
      });
  }

  dismiss() {
    this.confettiService.reset();
    this.spinService.setSpin(undefined);
    this.session.setShowWinnings(false);
    this.session.setAwardAnimation(false);
    this.isMobile$.pipe(take(1)).subscribe((isMobile) => {
      this.router.navigate([isMobile ? '/m/game' : '/d/game'], {
        queryParamsHandling: 'preserve',
      });
    });
  }

  playNow(link: string, hasWon: boolean): void {
    this.session.game$.pipe(take(1)).subscribe((game) => {
      if (!game) return;

      if (game.settlementPostMessage) {
        emitIframeMessage(
          hasWon ? IframeMessage.FinalAction : IframeMessage.Close,
        );
        return;
      }

      emitIframeMessage('upsell-1');

      this.session
        .sendAction(UserAction.CLICK_CLAIM_PRIZE, {})
        .pipe(take(1))
        .subscribe(() => {
          (window.top || window).location.href = link;
        });
    });
  }

  deposit() {
    emitIframeMessage('deposit');
    this.session
      .sendAction(UserAction.CLICK_DEPOSIT, {})
      .pipe(
        switchMap(() => this.session.game$),
        take(1),
        filter((game) => !!game?.depositLink),
      )
      .subscribe((game) => {
        (window.top || window).location.href = game!.depositLink!;
      });
  }

  close() {
    if (this.isFromHub) {
      this.document.location.href = `${environment.hubFrontendUrl}?token=${this.session.thirdPartyToken}&gameUuid=${this.session.hubGameUuid}`;
    } else {
      emitIframeMessage('close');
      this.session.game$
        .pipe(
          take(1),
          filter((game) => !!game?.closeLink),
        )
        .subscribe((game) => {
          console.log('closeLink', game!.closeLink);
          (window.top || window).location.href = game!.closeLink!;
        });
    }
  }

  private initSpinInfo(): void {
    this.spinService.spinInfo$
      .pipe(
        take(1),
        filter((info) => !!info && info.nextSpinInSeconds > 0),
      )
      .subscribe(() => {
        this.spinService.updateShowNextSpinInfo(true);
      });
  }

  private initConfetti(): void {
    combineLatest([
      this.won$.pipe(take(1)),
      this.collectibles$.pipe(take(1)),
      this.session.awardAnimationPlayed$.pipe(take(1)),
    ])
      .pipe(
        filter(
          ([won, collectedCollectibles, awardAnimationPlayed]) =>
            (won || !!collectedCollectibles.length) && !awardAnimationPlayed,
        ),
      )
      .subscribe(() => {
        this.triggerConfetti(true);
      });
  }

  private triggerConfetti(big = false) {
    this.confettiService.shoot(50, 500);
    if (big) {
      this.confettiService.shoot(100, 1500);
      this.confettiService.shoot(70, 3000);
    }
  }

  private getWinningsData(winnings: Winning[], game: Game): CarouselItem[] {
    // note: I moved this logic from html component
    const claimLink = winnings.find((w) => w.collectibleGoal?.claimLink)
      ?.collectibleGoal?.claimLink;
    return winnings.map((win) => {
      const assetUrl =
        win.collectibleGoal.claimAsset?.url ||
        win.collectibleGoal.icon.url ||
        (game!.featuredProducts?.length && game!.featuredProducts[0].asset.url);
      return {
        assetUrl,
        link: claimLink || game!.ctaLink,
        name: `+${win.collectibleGoal.prizeName}`,
        ctaLabel: win.collectibleGoal.ctaLabel,
        bonusType: win.collectibleGoal.bonusType,
      } as CarouselItem;
    });
  }
}
