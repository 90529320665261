export var QuestionType;
(function (QuestionType) {
  QuestionType["Options"] = "OPTIONS";
  QuestionType["CorrectScore"] = "CORRECT_SCORE";
  QuestionType["NumberSelect"] = "NUMBER_SELECT";
  QuestionType["Totals"] = "TOTALS";
})(QuestionType || (QuestionType = {}));
export var QuestionStatus;
(function (QuestionStatus) {
  QuestionStatus["PENDING"] = "PENDING";
  QuestionStatus["WON"] = "WON";
  QuestionStatus["LOST"] = "LOST";
})(QuestionStatus || (QuestionStatus = {}));
export var QuizQuestionNumberSelectOutcome;
(function (QuizQuestionNumberSelectOutcome) {
  QuizQuestionNumberSelectOutcome["PENDING"] = "PENDING";
  QuizQuestionNumberSelectOutcome["WON"] = "WON";
  QuizQuestionNumberSelectOutcome["LOST"] = "LOST";
  QuizQuestionNumberSelectOutcome["VOIDED"] = "VOIDED";
})(QuizQuestionNumberSelectOutcome || (QuizQuestionNumberSelectOutcome = {}));